<template>
  <div class="pd-24 bx-bd">
    <div class="pad-container">
      <el-tabs v-model="storeId" @tab-click="changeStore">
        <el-tab-pane
          :label="item.storeName"
          :name="item.id + ''"
          v-for="(item, index) in shopList"
          :key="index"
        >
        </el-tab-pane>
      </el-tabs>
      <el-row class="mb-20">
        <div class="flx-row ali-c js-btw">
          <el-card class="box_card">
            <p class="c-3 tl-c">会员总数</p>
            <div class="c-3 fw-7 fs-40 tl-c">
              {{ userTotalNumber }}
              <span class="c-9 fs-18">个</span>
            </div>
          </el-card>
          <el-card class="box_card">
            <p class="c-3 tl-c">订单总数</p>
            <div class="c-3 fw-7 fs-40 tl-c">
              {{ orderObj.orderTotal }}
              <span class="c-9 fs-18">个</span>
            </div>
          </el-card>
          <el-card class="box_card">
            <p class="c-3 tl-c">总金额</p>
            <div class="c-3 fw-7 fs-40 tl-c">
              {{ totalPriceNumber }}
              <span class="c-9 fs-18">元</span>
            </div>
          </el-card>
          <el-card class="box_card">
            <p class="c-3 tl-c">商品总数</p>
            <div class="c-3 fw-7 fs-40 tl-c">
              {{ goodsTotallNumber }}
              <span class="c-9 fs-18">个</span>
            </div>
          </el-card>
        </div>
      </el-row>
      <el-row class="mb-20">
        <div class="flx-row ali-c js-btw">
          <el-card class="box_card">
            <p class="c-3 tl-c">今日订单数</p>
            <div class="c-3 fw-7 fs-40 tl-c">
              {{ orderObj.orderToday }}
              <span class="c-9 fs-18">个</span>
            </div>
          </el-card>
          <el-card class="box_card">
            <p class="c-3 tl-c">昨日订单数</p>
            <div class="c-3 fw-7 fs-40 tl-c">
              {{ orderObj.orderToYesterday }}
              <span class="c-9 fs-18">个</span>
            </div>
          </el-card>
          <el-card class="box_card">
            <p class="c-3 tl-c">近七天订单数</p>
            <div class="c-3 fw-7 fs-40 tl-c">
              {{ orderObj.orderToWeek }}
              <span class="c-9 fs-18">个</span>
            </div>
          </el-card>
          <el-card class="box_card">
            <p class="c-3 tl-c">本月订单数</p>
            <div class="c-3 fw-7 fs-40 tl-c">
              {{ orderObj.orderToMonth }}
              <span class="c-9 fs-18">个</span>
            </div>
          </el-card>
        </div>
      </el-row>
      <el-row>
        <div class="pad-container flx-row ali-c js-btw">
          <order-chart
            title="本月核销用户数"
            :chartData="offsetLineChartArr"
            width="60%"
          />
          <el-card
            class="box_card box_card2"
            :body-style="{
              height: '200px',
              dispaly: 'flex',
              flexDirection: 'column',
            }"
          >
            <p class="c-3 tl-c">当日剩余待核销数量</p>
            <div class="c-3 fw-7 fs-40 tl-c mt-40">
              {{ waitOffsetCountNumber }}
              <span class="c-9 fs-18">个</span>
            </div>
          </el-card>
        </div>
      </el-row>
    </div>
  </div>
</template>
<script>
import orderChart from "./components/OrderChart";
import { getStoreList } from "@/api/online_department_store/commodity_manage";
import {
  userTotal,
  totalPrice,
  goodsTotall,
  offsetLineChart,
  waitOffsetCount,
  queryOrderData,
} from "@/api/dashboard/index";
const echarts = require("echarts");
export default {
  name: "Dashboard",
  components: {
    orderChart,
  },
  data() {
    return {
      storeId: "",
      shopList: [],
      userTotalNumber: 0,
      totalPriceNumber: 0,
      goodsTotallNumber: 0,
      waitOffsetCountNumber: 0,
      offsetLineChartArr: [],
      orderObj: {
        orderToMonth: 0,
        orderToWeek: 0,
        orderToYesterday: 0,
        orderToday: 0,
        orderTotal: 0,
      },
    };
  },
  mounted() {
    this.getStores(); //获取门店列表
  },
  methods: {
    // 获取门店列表
    getStores() {
      getStoreList().then((res) => {
        this.shopList = res.data.body.list;
        this.storeId = this.shopList[0].id.toString();
        this.refreshData();
      });
    },
    refreshData() {
      this.getqueryOrderData();
      this.getuserTotal();
      this.gettotalPrice();
      this.getgoodsTotall();
      this.getwaitOffsetCount();
      this.getoffsetLineChart();
    },
    getoffsetLineChart() {
      offsetLineChart(this.storeId).then((res) => {
        this.offsetLineChartArr = res.data.body;
      });
    },
    getwaitOffsetCount() {
      //待核销数量
      waitOffsetCount(this.storeId).then((res) => {
        this.waitOffsetCountNumber = res.data.body;
      });
    },
    getgoodsTotall() {
      //商品总数
      goodsTotall(this.storeId).then((res) => {
        this.goodsTotallNumber = res.data.body;
      });
    },
    gettotalPrice() {
      //总金额
      totalPrice(this.storeId).then((res) => {
        this.totalPriceNumber = res.data.body ? res.data.body : 0;
      });
    },
    getuserTotal() {
      // 获取会员总数
      userTotal(this.storeId).then((res) => {
        this.userTotalNumber = res.data.body;
      });
    },
    getqueryOrderData() {
      // 获取订单数据(总数 昨 今 当月 近七天)
      queryOrderData(this.storeId).then((res) => {
        this.orderObj = res.data.body;
      });
    },
    // 切换门店查询
    changeStore(tab) {
      this.storeId = tab.name;
      this.refreshData();
    },
  },
};
</script>

<style lang="scss" scoped>
.box_card {
  width: 24%;
  border-radius: 12px;
}
.box_card2 {
  width: 36%;
}
</style>
