import request from '@/utils/request'
// 本月核销用户折线图
export function offsetLineChart(storeId) {
    return request({
        url: `/order-service/dashboard/offsetLineChart?storeId=${storeId}`,
        method: 'get'
    })
}
// 当日等待核销数
export function waitOffsetCount(storeId) {
    return request({
        url: `/order-service/dashboard/waitOffsetCount?storeId=${storeId}`,
        method: 'get'
    })
}

// 商品总数
export function goodsTotall(storeId) {
    return request({
        url: `/order-service/dashboard/goodsTotal?storeId=${storeId}`,
        method: 'get'
    })
}
// 总金额
export function totalPrice(storeId) {
    return request({
        url: `/order-service/dashboard/totalPrice?storeId=${storeId}`,
        method: 'get'
    })
}
// 会员总数
export function userTotal() {
    return request({
        url: '/order-service/dashboard/userTotal',
        method: 'get'
    })
}
//获取订单数据(总数 昨 今 当月 近七天)
export function queryOrderData(storeId) {
    return request({
        url: `/order-service/dashboard/queryOrderData?storeId=${storeId}`,
        method: 'get'
    })
}